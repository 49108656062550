import React, { Component } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as contentful from 'contentful'
import { GroupPageLayout } from '../../../components/layouts'
import { withPreview } from '../../../components/common'
import { pagesGroupChildTypes } from '../../../utils/url'
import { PreviewHelper } from '../../../helpers'
import map from 'lodash/map'

class GroupPagePTPPreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            group: null,
            sitemap: [],
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props
            .getPowerToProtectSitemap()
            .then((sitemap) => {
                this.setState({ sitemap })
            })
            .catch((error) => {
                console.error('Error fetching sitemap:', error)
            })

        this.props
            .getPowerToProtectEntry('pageGroupPTP', this.props.location.search)
            .then((entry) => {
                if (!entry || !entry.sys || !entry.sys.id) {
                    throw new Error('Invalid entry received')
                }

                const powerToProtectClient = contentful.createClient({
                    space: process.env.GATSBY_CONTENTFUL_PTP_SPACE_ID, // Identifiants pour "Power to Protect"
                    accessToken: process.env.GATSBY_CONTENTFUL_PTP_PREVIEW_TOKEN,
                    environment: process.env.GATSBY_CONTENTFUL_PTP_ENVIRONMENT,
                    host: 'preview.contentful.com',
                })

                return powerToProtectClient
                    .getEntries({
                        links_to_entry: entry.sys.id,
                        locale: 'en-NZ',
                        'sys.contentType.sys.id[in]': pagesGroupChildTypes.join(','),
                        include: 1,
                    })
                    .then((data) => this.initializeGroupPage(entry, data))
                    .catch(() => {
                        console.error('Cannot load group preview')
                        return null
                    })
            })
            .catch((error) => {
                console.error('Error fetching entry:', error)
            })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    }

    initializeGroupPage(entry, data) {
        if (!entry || !entry.fields) {
            console.error('Invalid entry received')
            return
        }

        const page__generic = []
        const page__widgets = []
        const pages_group = []

        if (data && data.items) {
            data.items.forEach((item) => {
                let arr = [],
                    __typename = ''
                if (item.sys.contentType.sys.id === 'pageGroupPTP') {
                    arr = pages_group
                    __typename = 'ContentfulPageGroupPtp'
                } else if (item.sys.contentType.sys.id === 'pageGenericPTP') {
                    arr = page__generic
                    __typename = 'ContentfulPageGenericPtp'
                } else if (item.sys.contentType.sys.id === 'pageWidgetsPTP') {
                    arr = page__widgets
                    __typename = 'ContentfulPageWidgetsPtp'
                }

                const summary = item.fields.summary ? { summary: item.fields.summary } : undefined

                arr.push({
                    __typename,
                    ...item.fields,
                    summary,
                })
            })
        }

        const group = {
            __typename: 'ContentfulPageGroupPtp',
            contentful_id: entry.sys.id,
            page__generic,
            page__widgets,
            pages_group,
            ...entry.fields,
            headerImage: entry.fields.headerImage ? entry.fields.headerImage.fields : null,
            image: entry.fields.image ? entry.fields.image.fields : null,
            summary: entry.fields.summary ? { summary: entry.fields.summary } : null,
            groups: entry.fields.groups ? entry.fields.groups.map((group) => group.fields) : [],
            sections: entry.fields.sections
                ? map(entry.fields.sections, (section) => ({
                      ...section.fields,
                      widgets: section.fields.widgets ? map(section.fields.widgets, (widget) => PreviewHelper.parse(widget)) : [],
                  }))
                : [],
        }

        this.setState({ group })
    }

    render() {
        const { group, sitemap } = this.state

        return !this.props.loading && group ? <GroupPageLayout className="ptp-root" preview={true} group={group} sitemap={sitemap} /> : <></>
    }
}

export default withPreview(GroupPagePTPPreview)
